import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Login from "./components/Login";
import NarrativeManagement from "./components/NarrativeManagement";
import Example from "./components/Example";
import NarrativeMain from "./components/NarrativeMain";
import NarrativeEdit from "./components/NarrativeEdit";
import NarrativeList from "./components/NarrativeList";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GeoJsonPicker from "./components/GeoJsonPicker";

const App = () => {
  return (
    <Router>
      <Header />
      <div className="content">
        <div className="columns is-justify-content-center">
          <div className="column is-half">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/example" element={<Example />} />
              <Route path="/narratives/:id/edit" element={<NarrativeEdit />} />
              <Route path="/narratives/:id/geojson" element={<GeoJsonPicker />} />
              <Route path="/narratives/:id" element={<NarrativeMain />} />
              <Route path="/narratives/manage" element={<NarrativeManagement />} />
              <Route path="/narratives" element={<NarrativeList />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
        </div>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
