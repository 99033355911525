/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'bulma/css/bulma.min.css';
import { UserContext } from "../context/UserContext";
import { API_BASE_URL } from '../config';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow
});

const GeoJsonPicker = ({ onGeoJsonChange = () => { } }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [drawnItems] = useState(new L.FeatureGroup());
    const [geoJsonData, setGeoJsonData] = useState('');
    const [locationId, setLocationId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { token } = useContext(UserContext);

    useEffect(() => {
        const fetchGeoJsonData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/narratives/${id}/mappings`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    const location = data[0]?.location;
                    const fetchedLocationId = data[0]?.id;
                    setLocationId(fetchedLocationId);

                    if (location) {
                        try {
                            drawnItems.clearLayers();
                            const geoJsonLayer = L.geoJSON(location, {
                                onEachFeature: (feature, layer) => {
                                    if (feature.properties && feature.properties.notes) {
                                        layer.bindPopup(getPopupContent(feature.properties.notes));
                                    } else {
                                        layer.bindPopup(getPopupContent(''));
                                    }
                                    layer.on('popupopen', (e) => {
                                        const popupContent = getPopupContent(layer.feature.properties ? layer.feature.properties.notes : '');
                                        e.popup.setContent(popupContent);
                                        addPopupEventListeners(layer);
                                    });
                                    drawnItems.addLayer(layer);
                                }
                            });

                            if (map && isValidGeoJsonBounds(geoJsonLayer)) {
                                map.fitBounds(geoJsonLayer.getBounds());
                            }

                            updateGeoJsonData();
                        } catch (layerError) {
                            console.error('Error adding GeoJSON layer:', layerError);
                        }
                    }
                } else {
                    const errorText = await response.text();
                    setErrorMessage(`Failed to fetch GeoJSON data: ${response.statusText}`);
                    console.error('Failed to fetch GeoJSON data:', response.statusText, errorText);
                }
            } catch (fetchError) {
                setErrorMessage(`Error fetching GeoJSON: ${fetchError.message}`);
                console.error('Error fetching GeoJSON:', fetchError);
            }
        };

        if (mapRef.current && !map) {
            try {
                const leafletMap = L.map(mapRef.current).setView([68.7377, 15.4254], 10);

                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(leafletMap);

                const drawControl = new L.Control.Draw({
                    edit: {
                        featureGroup: drawnItems,
                        edit: true,
                        remove: true
                    },
                    draw: {
                        polygon: true,
                        polyline: true,
                        rectangle: true,
                        circle: false,
                        marker: true
                    }
                });

                leafletMap.addControl(drawControl);
                leafletMap.addLayer(drawnItems);

                leafletMap.on(L.Draw.Event.CREATED, (e) => {
                    const { layer } = e;
                    layer.feature = { type: "Feature", properties: {} };
                    layer.bindPopup(getPopupContent(''));
                    layer.on('popupopen', (e) => {
                        const popupContent = getPopupContent(layer.feature.properties ? layer.feature.properties.notes : '');
                        e.popup.setContent(popupContent);
                        addPopupEventListeners(layer);
                    });
                    drawnItems.addLayer(layer);
                    handleGeoJsonChange();
                });

                leafletMap.on(L.Draw.Event.EDITED, handleGeoJsonChange);
                leafletMap.on(L.Draw.Event.DELETED, handleGeoJsonChange);

                setMap(leafletMap);
            } catch (mapError) {
                setErrorMessage(`Error initializing Leaflet map: ${mapError.message}`);
                console.error('Error initializing Leaflet map:', mapError);
            }
        }

        fetchGeoJsonData();
    }, [id, map, drawnItems, token]);

    const isValidGeoJsonBounds = (geoJsonLayer) => {
        try {
            const bounds = geoJsonLayer.getBounds();
            if (!bounds.isValid()) {
                return false;
            }
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();
            if (!northEast || !southWest) {
                return false;
            }
            return true;
        } catch (e) {
            console.error("Error checking GeoJSON bounds:", e);
            return false;
        }
    };

    const handleGeoJsonChange = () => {
        try {
            updateGeoJsonData();
            const geoJsonData = drawnItems.toGeoJSON();
            onGeoJsonChange(geoJsonData);
        } catch (geoJsonError) {
            console.error('Error updating GeoJSON data:', geoJsonError);
        }
    };

    const updateGeoJsonData = () => {
        try {
            const geoJson = drawnItems.toGeoJSON();
            setGeoJsonData(JSON.stringify(geoJson, null, 2));
        } catch (geoJsonError) {
            console.error('Error updating GeoJSON data:', geoJsonError);
        }
    };

    const handleTextareaChange = (e) => {
        setGeoJsonData(e.target.value);
        try {
            const parsedGeoJson = JSON.parse(e.target.value);
            drawnItems.clearLayers();
            const geoJsonLayer = L.geoJSON(parsedGeoJson, {
                onEachFeature: (feature, layer) => {
                    if (feature.properties && feature.properties.notes) {
                        layer.bindPopup(getPopupContent(feature.properties.notes));
                    } else {
                        layer.bindPopup(getPopupContent(''));
                    }
                    layer.on('popupopen', (e) => {
                        const popupContent = getPopupContent(layer.feature.properties ? layer.feature.properties.notes : '');
                        e.popup.setContent(popupContent);
                        addPopupEventListeners(layer);
                    });
                    drawnItems.addLayer(layer);
                }
            });
            if (map && isValidGeoJsonBounds(geoJsonLayer)) {
                map.fitBounds(geoJsonLayer.getBounds());
            }
        } catch (error) {
            console.error('Invalid GeoJSON:', error);
        }
    };

    const saveGeoJson = async () => {
        try {
            const parsedGeoJson = JSON.parse(geoJsonData);
            const data = {
                location: parsedGeoJson
            };
            const url = locationId ?
                `${API_BASE_URL}/narratives/${id}/mappings/${locationId}` :
                `${API_BASE_URL}/narratives/${id}/mappings`;
            const method = locationId ? 'PUT' : 'POST';
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                const errorText = await response.text();
                setErrorMessage(`Failed to save GeoJSON: ${errorText}`);
                throw new Error(`Failed to save GeoJSON: ${errorText}`);
            }
            setErrorMessage('');
            setSuccessMessage('GeoJSON saved successfully!');
            navigate(`/narratives/${id}/edit`);
        } catch (saveError) {
            setErrorMessage(`Error saving GeoJSON: ${saveError.message}`);
            setSuccessMessage('');
        }
    };

    const getPopupContent = (notes) => {
        return `
            <div class="box">
                <textarea id="popup-notes" class="textarea" placeholder="Add notes here..." style="width: 240px; height: 60px;">${notes || ''}</textarea>
                <button id="save-notes" class="button is-primary" style="width: 100%; margin-top: 5px;">Save</button>
            </div>
        `;
    };

    const addPopupEventListeners = (layer, feature) => {
        document.getElementById('save-notes').addEventListener('click', () => {
            const notes = document.getElementById('popup-notes').value;
            if (!layer.feature) {
                layer.feature = { type: "Feature", properties: {} };
            }
            if (!layer.feature.properties) {
                layer.feature.properties = {};
            }
            layer.feature.properties.notes = notes;

            // Update the popup content with the new notes
            layer.setPopupContent(getPopupContent(notes));

            layer.closePopup();
            handleGeoJsonChange();
        });
    };

    return (
        <div className="container">
            <h1 className="title">Location Editor</h1>
            {errorMessage && (
                <div className="notification is-danger">
                    <button className="delete" onClick={() => setErrorMessage('')}></button>
                    {errorMessage}
                </div>
            )}
            {successMessage && (
                <div className="notification is-success">
                    <button className="delete" onClick={() => setSuccessMessage('')}></button>
                    {successMessage}
                </div>
            )}
            <div ref={mapRef} style={{ height: '500px', width: '100%' }} />
            <div className="field is-grouped is-grouped-right" style={{ marginTop: '10px' }}>
                <p className="control">
                    <button className="button is-primary" onClick={saveGeoJson}>Save GeoJSON</button>
                </p>
            </div>
            <div className="field">
                <label className="label">Current GeoJSON</label>
                <div className="control">
                    <textarea
                        className="textarea"
                        value={geoJsonData || ''}
                        onChange={handleTextareaChange}
                        rows="10"
                    ></textarea>
                </div>
            </div>
        </div>
    );
};

export default GeoJsonPicker;
